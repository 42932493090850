import firebase from 'firebase/app'
import 'firebase/analytics'

export const dynamicLinkAPIKey = 'AIzaSyC5uyC0md0BxmGMHplQ5tOZ7pYpyBMKOSk'

// staging
/* const firebaseConfig = {
  apiKey: 'AIzaSyC0Yn2GIQLVV_ZO8z9XH9yCkwiB1DLymKA',
  authDomain: 'goodmorningnight-staging.firebaseapp.com',
  databaseURL: 'https://goodmorningnight-staging-default-rtdb.firebaseio.com',
  projectId: 'goodmorningnight-staging',
  storageBucket: 'goodmorningnight-staging.appspot.com',
  messagingSenderId: '67309237731',
  appId: '1:67309237731:web:dffb13c383e1b92515dcd6',
  measurementId: 'G-2K3J8M0E8Q'
} */

// Live:
const firebaseConfig = {
  apiKey: 'AIzaSyCJNee9-a5vSRoUD47qPUZV8QkrG61CCTQ',
  authDomain: 'goodmorningnight-live.firebaseapp.com',
  databaseURL: 'https://goodmorningnight-live-default-rtdb.firebaseio.com',
  projectId: 'goodmorningnight-live',
  storageBucket: 'goodmorningnight-live.appspot.com',
  messagingSenderId: '297722354719',
  appId: '1:297722354719:web:7231f7207482cacd46092a',
  measurementId: 'G-3EK3W2KVSP'
}

export const firebaseApp = firebase.initializeApp(firebaseConfig)

export function enableAnalytics() {
  firebaseApp.analytics().setAnalyticsCollectionEnabled(true)
}

export function disableAnalytics() {
  firebaseApp.analytics().setAnalyticsCollectionEnabled(false)
}
