{
  "appname": "Good Morning and Night",
  "deletionAppName": "Good Morning and Night App",
  "mail": "{'support@goodmorningnight.app'}",
  "getTheApp": "Hol dir die Good Morning and Night App!",
  "home-header": "Sag Guten Morgen!",
  "home-subheader": "Entdecke täglich neue Grüße passend zu jeder Tageszeit und teile sie kostenlos auf WhatsApp, Facebook Messenger oder allen anderen Plattformen.",
  "dataprotection_header_web": "Datenschutzerklärung von Good Morning and Night (Web Version)",
  "dataprotection_header_app": "Datenschutzerklärung von Good Morning and Night"
}
