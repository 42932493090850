{
  "appname": "MegaLOL",
  "deletionAppName": "MegaLOL App",
  "mail": "{'support@mega.lol'}",
  "getTheApp": "Hol dir die MegaLOL App!",
  "home-header": "Immer was zu lachen!",
  "home-subheader": "Langeweile? Machst Du Witze?! 😂 Lol. Unsere tägliche Dosis Spaß bringt dich und deine Freunde garantiert zum Lachen!",
  "dataprotection_header_web": "Datenschutzerklärung von MegaLoL (Web Version)",
  "dataprotection_header_app": "Datenschutzerklärung von MegaLoL (App)"
}
