{
  "appname": "MegaLOL",
  "deletionAppName": "MegaLOL App",
  "mail": "{'support@mega.lol'}",
  "getTheApp": "Get the MegaLOL App!",
  "home-header": "Your daily dose of fun!",
  "home-subheader": "Share funny videos, a funny picture, funny quotes & jokes in WhatsApp, on Facebook Messenger or post funny memes, clips & GIFs on social networks, such as Instagram or Facebook, to bring some fun in your friends and followers life.",
  "dataprotection_header_web": "Privacy Policy of MegaLoL (Web Version)",
  "dataprotection_header_app": "Privacy Policy of MegaLoL (App)"
}
