{
  "appname": "Wishes",
  "deletionAppName": "Glückwünsche App",
  "mail": "{'support@glueckwuensche.app'}",
  "getTheApp": "Get the Wishes App!",
  "home-header": "Wishes & More",
  "home-subheader": "Surprise your friends with wishes and greeting cards! Send them lovely video greetings, wishes and quotes on pictures and show them how thankful you are!",
  "dataprotection_header_web": "Privacy Policy of Greetings App (Web Version)",
  "dataprotection_header_app": "Privacy Policy of Greetings App"
}
