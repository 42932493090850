{
  "appname": "Glückwünsche",
  "deletionAppName": "Glückwünsche App",
  "mail": "{'support@glueckwuensche.app'}",
  "getTheApp": "Hol dir die Glückwünsche App!",
  "home-header": "Die schönsten Glückwünsche und Grüße!",
  "home-subheader": "Teile unsere kostenlosen, deutschen Glückwünsche & Grüße zu Weihnachten, Advent, Silvester & Neujahr oder zum Geburtstag als Video oder Bild in WhatsApp & Facebook Messenger.",
  "dataprotection_header_web": "Datenschutzerklärung von Glückwünsche App (Web Version)",
  "dataprotection_header_app": "Datenschutzerklärung von Glückwünsche App"
}
