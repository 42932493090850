{
  "appname": "MoinMoin",
  "deletionAppName": "MoinMoin App",
  "mail": "{'support@moinmoin.io'}",
  "getTheApp": "Hol dir MoinMoin!",
  "home-header": "Sag Guten Morgen!",
  "home-subheader": "Entdecke täglich neue Grüße passend zu jeder Tageszeit und teile sie kostenlos auf WhatsApp, Facebook Messenger oder allen anderen Plattformen.",
  "dataprotection_header_web": "Datenschutzerklärung von MoinMoin (Web Version)",
  "dataprotection_header_app": "Datenschutzerklärung von MoinMoin"
}
