{
  "appname": "MoinMoin",
  "deletionAppName": "MoinMoin App",
  "mail": "{'support@moinmoin.io'}",
  "getTheApp": "Get the MoinMoin App!",
  "home-header": "Say good morning!",
  "home-subheader": "Discover new greetings every day suitable for any time of day and share them for free on WhatsApp, Facebook Messenger or all other platforms.",
  "dataprotection_header_web": "Privacy Policy of MoinMoin (Web Version)",
  "dataprotection_header_app": "Privacy Policy of MoinMoin"
}
