{
  "appname": "Sprüche App",
  "deletionAppName": "Sprüche App",
  "mail": "{'support@sprueche-app.de'}",
  "getTheApp": "Hol dir die Sprüche App!",
  "home-header": "Die besten Sprüche!",
  "home-subheader": "Schicke Liebe, Motivation, Glückwünsche & Weisheiten für jede Lebenslage in WhatsApp, auf Instagram, Facebook Messenger und auf allen anderen Plattformen.",
  "dataprotection_header_web": "Datenschutzerklärung von Sprüche App (Web Version)",
  "dataprotection_header_app": "Datenschutzerklärung von Sprüche App"
}
