{
  "appname": "Good Morning and Night",
  "deletionAppName": "Good Morning and Night App",
  "mail": "{'support@goodmorningnight.app'}",
  "getTheApp": "Get the Good Morning and Night App!",
  "home-header": "Say good morning!",
  "home-subheader": "Discover new greetings every day suitable for any time of day and share them for free on WhatsApp, Facebook Messenger or all other platforms.",
  "dataprotection_header_web": "Privacy Policy of Good Morning and Night (Web Version)",
  "dataprotection_header_app": "Privacy Policy of Good Morning and Night App"
}
