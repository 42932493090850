{
  "appname": "Quotes App",
  "deletionAppName": "Quotes App",
  "mail": "{'support@sprueche-app.de'}",
  "getTheApp": "Get the Quotes App!",
  "home-header": "The best greetings!",
  "home-subheader": "Send quotes about Love, Motivation, Wishes & Greetings for every occasion in WhatsApp, Instagram, Facebook Messenger and every other platform.",
  "dataprotection_header_web": "Privacy Policy of Quotes App (Web Version)",
  "dataprotection_header_app": "Privacy Policy of Quotes App"
}
